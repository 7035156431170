import { sticky }              from '@/utils/dom';
import { executeOnWindowLoad } from '@/utils/withWindowLoad';

executeOnWindowLoad(() => {
    const anchor = document.querySelector<HTMLElement>('.anchor-container');
    const container = document.querySelector<HTMLElement>('#content-container');

    if (anchor && container) {
        sticky(anchor, container);
    }
});
