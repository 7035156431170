import './styles.scss';

import { register, SwiperContainer } from 'swiper/element';
import { Navigation }                from 'swiper/modules';
import { SwiperOptions }             from 'swiper/types/swiper-options';

register();

const contentSliders = Array.from(document.querySelectorAll<SwiperContainer>(
    'div.content-swiper swiper-container',
));

contentSliders.forEach(contentSlider => {
    const sliderOptions: SwiperOptions = {
        // Default settings
        enabled: true,

        modules: [Navigation],

        direction:     'horizontal',
        speed:         500,
        slidesPerView: 'auto',
        spaceBetween:  32,

        navigation: {
            enabled: true,
            nextEl:  contentSlider.querySelector<HTMLDivElement>('button.swiper-next')!,
            prevEl:  contentSlider.querySelector<HTMLDivElement>('button.swiper-prev')!,
        },
    };

    Object.assign(contentSlider, sliderOptions);
    contentSlider.initialize();

    const updateNavButtons = () => {
        const navButtonContainer = contentSlider.querySelector<HTMLDivElement>('div[slot=container-start]')!;
        const slideWrapper = contentSlider.shadowRoot!.querySelector<HTMLDivElement>('[part="wrapper"]')!;

        if (slideWrapper.scrollWidth > slideWrapper.clientWidth) {
            navButtonContainer.classList.remove('hidden', 'd-none');
        } else {
            navButtonContainer.classList.add('hidden', 'd-none');
        }
    };

    setTimeout(() => {
        window.addEventListener('resize', updateNavButtons);
        updateNavButtons();
    }, 0);
});
